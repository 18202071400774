<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-tanks' }">{{
            $t("MENU.ITEM.TANKS.TANKS")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.EDIT") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['tanks-delete']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingTank"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            v-if="!firstLoader"
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>
          <v-icon size="20" color="error">mdi-alert-circle</v-icon>
          <span>
            За да добавите количество моля използвайте страницата "Разходи
            горива".
          </span>
          <v-overlay :value="!firstLoader && isLoadingTank">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <DeleteButton
            v-on:onDelete="onDelete()"
            v-permission="['tanks-delete']"
          ></DeleteButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingTank"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_TANK,
  UPDATE_TANK,
  DELETE_TANK,
} from "@/modules/tanks/store/tanks.module";
import { FETCH_FUELS_TYPES } from "@/modules/fuels-types/store/fuel-type.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      firstLoader: true,
      id: null,
      fuel_type: [],
      type: "tank",
    };
  },
  components: {
    KTCard,
    FormBuilder,
    FormInput,
    SaveButtonDropdown,
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.TANKS.TANKS"),
        route: { name: "list-tanks" },
      },
      { title: this.$i18n.t("MENU.ITEM.EDIT") },
    ]);
    vm.getTanks();
    this.$store.dispatch(FETCH_FUELS_TYPES).then((data) => {
      this.fuel_type = data.data.items;
    });
  },
  computed: {
    ...mapGetters(["isLoadingTank"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "input",
            inputType: "text",
            id: "location-input",
            groupId: "provider-name-group",
            required: "required",
            colSm: "3",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.TANK_NAME",
              placeholder: "FORM_LABELS.TANK_NAME_PLACEHOLDER",
              validation: "FORM.VALID_NAME",
            },
            validations: {
              required: true,
              minLength: 2,
            },
            translatable: false,
            model: "locationName",
          },
          {
            type: "input",
            inputType: "text",
            id: "capacity-input",
            groupId: "provider-name-group",
            required: "required",
            colSm: "3",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "MENU.ITEM.TANKS.TANK_CAPACITY",
              placeholder: "MENU.ITEM.TANKS.TANK_CAPACITY_PLACEHOLDER",
              validation: "FORM.VALID_NAME",
            },
            validations: {
              required: true,
            },
            translatable: false,
            model: "capacity",
          },
          {
            type: "input",
            inputType: "text",
            id: "address-input",
            groupId: "provider-name-group",
            required: "required",
            colSm: "3",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.LOCATION",
              placeholder: "FORM_LABELS.TANK_LOCATION_PLACEHOLDER",
              validation: "FORM.VALID_NAME",
            },
            validations: {},
            translatable: false,
            model: "address",
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-unit-input",
            feedback_id: "unit-live-feedback",
            colSm: "3",
            divider: false,
            clearable: false,
            i18n: {
              label: "FORM_LABELS.MACHINE_FUEL_TYPE",
              placeholder: "FORM_PLACEHOLDERS.MACHINE_FUEL_TYPE",
            },
            options: this.fuel_type,
            validations: {
              required: true,
            },
            model: "fuelTypeId",
          },
          {
            type: "input",
            inputType: "text",
            id: "liters-available-input",
            groupId: "liters-available-group",
            required: "required",
            colSm: "6",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "MENU.ITEM.FUELS.AVAILABLE_LITERS",
              placeholder: "",
              validation: "FORM.VALID_NAME",
            },
            validations: {},
            translatable: false,
            model: "litersAvailable",
            disabled: true,
          },
          {
            type: "input",
            inputType: "text",
            id: "avg-price-input",
            groupId: "avg-price-group",
            required: "required",
            colSm: "6",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "MENU.ITEM.FUELS.AVG_PRICE",
              placeholder: "",
              validation: "FORM.VALID_NAME",
            },
            validations: {},
            translatable: false,
            model: "avgPrice",
            disabled: true,
          },
        ],
      };
    },
  },
  validations() {
    let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    return tmpValidationObject;
  },
  methods: {
    getTanks() {
      let vm = this;
      let id = this.$route.params.id;
      this.id = id;
      this.$store
        .dispatch(FETCH_TANK, id)
        .then((data) => {
          vm.firstLoader = false;
          vm.$nextTick(function() {
            vm.tank = data.data;
            this.generateFormOutOfSchemaJson(this.schemaJson, vm.tank);
          });
        })
        .catch((response) => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Not Found",
            });
            this.$router.push({ name: "list-tanks" });
          }
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSave(next) {
      let vm = this;
      vm.form.type = this.type;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return;
      }

      let copyForm = JSON.parse(JSON.stringify(this.form));
      delete copyForm.litersAvailable;
      delete copyForm.avgPrice;
      this.$store
        .dispatch(UPDATE_TANK, { id: this.id, payload: copyForm })
        .then((data) => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });

          if (next == "continue") {
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-tanks" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-tanks" });
          }
        })
        .catch((response) => {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
    onDelete() {
      let vm = this;
      let message = vm.$t("BUTTON.PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(function() {
          vm.$store
            .dispatch(DELETE_TANK, vm.tank.id)
            .then((data) => {
              vm.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message,
              });
              vm.$router.push({ name: "list-tanks" });
            })
            .catch((response) => {
              vm.$notify({
                group: "notify",
                type: "error",
                title: this.$t("ALERTS.ERROR"),
                text: "Записът не може да бъде изтрит",
              });
            });
        })
        .catch(function() {});
    },
    resetVForm() {
      this.$v.$reset();
    },
  },
};
</script>
